


















































































import { PessoaService } from "@/core/services/Clientes";
import { AutenticadorService } from "@/core/services/geral";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  public loading: boolean = false;
  private esconderSenha: boolean = true;
  acesso: { login: string; senha: string } = {
    login: "",
    senha: ""
  };
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  service = new AutenticadorService();
  pessoaService = new PessoaService();
  token: string = '';
  resetarSenha: boolean = false;
  cadastrarUsuario: boolean = false;
  resetarModel: any = {
    login: "",
    senhaNova: "",
    token: ""
  }
  cpfCnpj: string = '';
  mskCnpj = process.env.VUE_APP_MSK_CNPJ;
  maskCpf = process.env.VUE_APP_MSK_CPF;
  mask = process.env.VUE_APP_MSK_CPF;

  mounted(){
    this.token = this.$route.params.token;
  }

  @Watch('resetarModel.login')
  @Watch('acesso.login')
  @Watch('cpfCnpj')
  WathCPFCNPJ(val: any){
    if(val.length > 14){
      this.mask = this.mskCnpj;
    }
    else{
      this.mask = this.maskCpf;
    }
  }

  @Watch('token')
  WatchToken(){
    if(!this.token)
      return;

    this.service.ConfirmarEmail(this.token).then(
      res =>{
        this.$swal('Aviso', res.data, res.status == 200 ? 'success' : 'warning');
      },
      err =>{
        this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
      }
    )
  }

  Login() {
    this.loading = true;

    this.service.AutenticarCliente(this.acesso).then(
      res => {
        localStorage.setItem('sessionApp', JSON.stringify(res.data));                  
        this.$swal('Aviso', res.data, res.status == 200 ? 'success' : 'warning');
        this.$router.push({ name: 'Home' });
      },
      err => {
        localStorage.clear();
        this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  CadastrarUsuario(realizar: boolean){
    if(realizar){
      this.loading = true;
      this.pessoaService.CriarUsuario(this.cpfCnpj).then(
        res => {
          this.$swal('Aviso', "Cadastrado com Sucesso!", 'success');
        },
        err =>{
          this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error')
        }
      ).finally(() => {
        this.loading = false;
      })
    }
    else{
      this.cadastrarUsuario = true;
    }
  }

  ResetarSenha(executar: boolean){
    if(executar){
      this.loading = true;
      this.service.ResetarSenha(this.resetarModel).then(
        res => {
          this.$swal('Aviso', res.data, res.status == 200 ? 'success' : 'warning');
          setTimeout(() => {
            location.reload(true);
          }, 2500);
        },
        err => {
          this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error');
        }
      ).finally(() => {
        this.loading = false;
      })
    }
    else{
      this.resetarSenha = true;
    }
  }
}
